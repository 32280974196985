<template>
  <ul class="detail-form" v-if="list">
    <li v-for="val in list" :key="val.key">
      <div class="detail-item">
        <div class="detail-label">{{val.label}}</div>
        <div class="detail-value" v-if="val.component == 'upload' && val.value && val.value.length > 0">
          <div class="detail-file-list">
            <div class="detail-file-item" v-for="(f, i) in val.value" :key="i" @click="preview(f)">
              <img class="detail-file-img" :src="f.url" v-if="f.type == 'image'">
              <img class="detail-file-img" :src="require(`../assets/file/${f.type}.png`)" v-else>
              <div class="detail-file-info">
                <div class="detail-file-name">{{f.fileName}}</div>
                <div class="detail-file-size">{{f.sizeText}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-value" v-else-if="val.component == 'gift' && val.value && val.value.length > 0">
          <div v-for="(g, i) in val.value" :key="i">{{g.name}} × {{g.num}}</div>
        </div>
        <div class="detail-value" v-else-if="val.component == 'subscribe' && val.value && val.value.length > 0">
          <div v-for="(g, i) in val.value" :key="i">{{g.goodsName}} × {{g.amount}}</div>
        </div>
        <div class="detail-value" v-else-if="val.component == 'asset' && val.value">
          <div class="detail-asset" v-for="(a, i) in val.value" :key="i">
            <template v-if="a.asset_code">
              <div class="detail-asset-name"><span class="asset-asset-no">{{a.asset_code}}</span> {{a.goods ? a.goods.name : ""}}</div>
              <div class="detail-asset-other">{{a.spec || ''}} {{a.ketang ? a.ketang.name : ""}}</div>
            </template>
            <template v-else>
              <div class="detail-asset-name"><span class="asset-asset-no">{{a.serialNumber}}</span> {{a.goodsName}}</div>
              <div class="detail-asset-other">{{a.specifications ? a.specifications + ";" : ""}} {{a.department}}</div>
            </template>
          </div>
        </div>
        <div class="detail-value" v-else-if="val.component == 'visitors' && val.value && val.value.length > 0">
          <div class="detail-visitor" v-for="(v, i) in val.value" :key="i">
            <div class="detail-visitor-name">{{v.name}}</div>
            <div class="detail-visitor-mobile">{{v.mobile}}</div>
            <div class="detail-visitor-charge" v-if="v.is_charge == 1">负责人</div>
          </div>
        </div>
        <div class="detail-value" v-else-if="val.component == 'car' && val.value">
          <div class="detail-value-p" v-if="typeof val.value === 'string'">{{val.value}}</div>
          <template v-else>
            <div class="detail-visitor detail-car" v-for="(v, i) in val.value" :key="i">
              <div class="detail-car-num">{{v.num}}</div>
              <div class="detail-visitor-name">{{v.name}}</div>
              <div class="detail-visitor-mobile">{{v.tel}}</div>
            </div>
          </template>
        </div>
        <div class="detail-value" v-else-if="val.component == 'book' || val.component == 'address_book'">
          <div class="book-item" v-for="b in val.value" :key="b.id">
            <a-icon class="book-icon" :type="b.type == 'user' ? 'user' : 'folder'" />
            <open-data :openid="b.id" :type="`${b.type}Name`"></open-data>
          </div>
        </div>
        <div class="detail-value" v-else-if="val.component == 'borrow_apply' && val.value">
          <a class="txt-btn" @click="showRelatedApply(val.value)">查看详情</a>
        </div>
        <div class="detail-value" v-else-if="val.value">
          <div class="detail-value-p" v-for="(p, i) in String(val.value).split(/\n/)" :key="i">{{ p }}</div>
        </div>
        <div class="detail-value" v-else> 无</div>
      </div>
    </li>
  </ul>
</template>

<script>
    export default{
        name: "detail-view",
        props: {
            list: Array
        },
        methods: {
            preview(f) {
                if(f.type == 'image') {
                    this.$emit("preview", f);
                } else {
                    window.open(f.url, '_blank');
                }
            },
            showRelatedApply(apply) {
                this.$emit("apply", apply);
            }
        }
    }
</script>

<style lang="less">
  .detail-item {
    display: flex;
    padding: 6px 24px;
    background-color: @component-background;
    line-height: 1.4;
    &.vertical {
      flex-direction: column;
      .detail-value {
        margin: 10px 0 0;
      }
    }
  }
  .detail-label {
    width: 60px;
    color: @text-color-secondary;
  }
  .detail-value {
    flex: 1;
    margin-left: 20px;
    min-width: 0;
    word-break: break-word;
    color: @text-color;
    text-align: justify;
  }

  .detail-file-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    background-color: @background-color-base;
    cursor: pointer;
  }

  .detail-file-item:not(:first-of-type) {
    margin-top: 8px;
  }
  .detail-file-info {
    margin-left: 6px;
  }
  .detail-file-img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    object-fit: cover;
  }

  .detail-file-text {
    flex: 1;
    min-height: 0;
    margin-left: 8px;
    font-size: 12px;
    line-height: 1.5;
  }
  .detail-asset {
    &:not(:first-of-type) {
      margin-top: 6px;
    }
  }
  .detail-file-size {
    margin-top: 4px;
    color: @text-color-secondary;
  }
  .asset-asset-no {
    color: @orange-6;
  }

  .detail-visitor {
    display: flex;
    align-items: center;
    width: 300px;
    padding: 4px 8px;
    border-radius: 2px;
    background-color: @background-color-base;
  }
  .detail-visitor:not(:first-child) {
    margin-top: 6px;
  }
  .detail-car-num {
    margin-right: 1em;
  }
  .detail-visitor-mobile {
    flex: 1;
    margin-left: 1em;
    color: @text-color-secondary;
  }
  .detail-car .detail-visitor-mobile {
    text-align: right;
  }
  .detail-visitor-charge {
    margin-left: 1em;
    color: #978A83;
    font-size: 12px;
  }
</style>
